import type { FC } from 'react';
import { createElement } from 'react';
import { getTestIdProp } from './get-test-id-prop';

export interface CreateFontProviderProps {
  className: string;
  testId: string;
}

export const createFontFamilyProvider = ({
  className,
  testId,
}: CreateFontProviderProps): FC =>
  function FontFamilyProvider(props) {
    return createElement('div', {
      className,
      ...getTestIdProp(testId),
      ...props,
    });
  };
